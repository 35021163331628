import { Footer, Header, Layout, Main } from "@/components/layout";
import config from "@/config.json";
import { Form } from "@/modules/common/components";
import { verifyDomainMiddlewere } from "@/modules/common/middlewares";
import { TurnstileService } from "@/modules/common/services";
import { drizzle, schema } from "@/modules/database";
import { useForm } from "@conform-to/react";
import { getZodConstraint, parseWithZod } from "@conform-to/zod";
import {
  ActionFunctionArgs,
  json,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import { Outlet, useFetcher, useLoaderData } from "@remix-run/react";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { and, desc, gte, lt, lte } from "drizzle-orm";
import { jsonWithToast } from "remix-toast";
import { z } from "zod";
import { Item } from "./components";

dayjs.extend(utc);
dayjs.extend(timezone);

const postSchema = z.object({
  email: z
    .string({ required_error: "This field is required." })
    .email("Please enter valid email address."),
  token: z.string(),
});

export type Loader = typeof loader;

export const meta: MetaFunction<Loader> = (args) => {
  if (!args.data) return [];

  return [
    {
      tagName: "link",
      rel: "canonical",
      content: args.data.meta.url,
    },
    {
      name: "robots",
      content: "index, follow",
    },
    {
      title: config.title,
    },
    {
      name: "description",
      content: config.description,
    },

    // Open Graph
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:locale",
      content: "en_US",
    },
    {
      property: "og:site_name",
      content: config.name,
    },
    {
      property: "og:url",
      content: args.data.meta.url,
    },
    {
      property: "og:title",
      content: config.title,
    },
    {
      property: "og:description",
      content: config.description,
    },
    {
      property: "og:image",
      content: args.data.meta.image,
    },

    // Twitter
    {
      name: "twitter:card",
      content: "summary_large_image",
    },
    {
      name: "twitter:site",
      content: `@${config.twitter}`,
    },
    {
      name: "twitter:title",
      content: config.title,
    },
    {
      name: "twitter:description",
      content: config.description,
    },
    {
      name: "twitter:image",
      content: args.data.meta.image,
    },
  ];
};

export const action = async (args: ActionFunctionArgs) => {
  await verifyDomainMiddlewere(process.env.APP_URL, args);

  const formData = await args.request.clone().formData();
  const submission = parseWithZod(formData, { schema: postSchema });

  if (submission.status != "success") {
    return json({
      result: submission.reply(),
    });
  }

  if (!(await TurnstileService.handle(submission.value.token))) {
    return json({
      result: submission.reply({
        formErrors: ["Failed to verify request."],
      }),
    });
  }

  await drizzle
    .insert(schema.subscribers)
    .values({
      email: submission.value.email.toLowerCase(),
    })
    .onConflictDoUpdate({
      target: [schema.subscribers.email],
      set: {
        email: submission.value.email.toLowerCase(),
      },
    })
    .returning();

  return jsonWithToast(null, {
    message: "Thank you for joining the community.",
    type: "success",
  });
};

export const loader = async (args: LoaderFunctionArgs) => {
  await verifyDomainMiddlewere(process.env.APP_URL, args);

  const today = await drizzle.query.products.findMany({
    where: and(
      lte(schema.products.launchedAt, dayjs().tz("utc").endOf("day").toDate()),
      gte(schema.products.launchedAt, dayjs().tz("utc").startOf("day").toDate())
    ),
    orderBy: desc(schema.products.launchedAt),
    limit: 30,
    columns: {
      id: true,
      name: true,
      slug: true,
      tagline: true,
      verified: true,
    },
    with: {
      tags: {
        columns: {
          id: true,
        },
        with: {
          tag: {
            columns: {
              id: true,
              name: true,
              slug: true,
            },
          },
        },
      },
      deals: {
        columns: {
          id: true,
        },
      },
    },
  });

  const week = await drizzle.query.products.findMany({
    where: lt(
      schema.products.launchedAt,
      dayjs().tz("utc").startOf("day").toDate()
    ),
    orderBy: desc(schema.products.launchedAt),
    limit: 20,
    columns: {
      id: true,
      name: true,
      slug: true,
      tagline: true,
      verified: true,
    },
    with: {
      tags: {
        columns: {
          id: true,
        },
        with: {
          tag: {
            columns: {
              id: true,
              name: true,
              slug: true,
            },
          },
        },
      },
      deals: {
        columns: {
          id: true,
        },
      },
    },
  });

  return json({
    meta: {
      url: process.env.APP_URL,
      image: `${process.env.APP_URL}/images/social/default.png`,
    },
    products: {
      today,
      week,
    },
  });
};

export default function Page() {
  const data = useLoaderData<Loader>();
  const fetcher = useFetcher<any>();

  const [form, fields] = useForm({
    shouldValidate: "onBlur",
    shouldRevalidate: "onInput",
    constraint: getZodConstraint(postSchema),
    lastResult: fetcher.data?.result,
    onValidate: ({ formData }) => {
      return parseWithZod(formData, { schema: postSchema });
    },
  });

  return (
    <Layout>
      <Header />

      <Main className="bg-[url('/images/pattern-1.png')] bg-contain bg-top bg-no-repeat">
        <div className="text-center">
          <h1 className="text-3xl font-bold md:text-4xl text-common-title">
            Discover Products and Tools for Your Business.
          </h1>

          <p className="mt-4 font-medium text-common-subtitle max-w-[600px] mx-auto">
            Discover top-rated tools and software for your business. Our
            directory helps you find products to streamline workflow, boost
            productivity, and stay competitive.
          </p>

          <div className="p-2 text-left bg-white border shadow-sm rounded-xl border-common-border mt-8 max-w-[500px] mx-auto">
            <Form form={form} fetcher={fetcher} method="post">
              <div className="flex gap-2">
                <div className="flex-grow">
                  <Form.Label meta={fields.email} className="hidden">
                    Email
                  </Form.Label>

                  <Form.Input
                    meta={fields.email}
                    placeholder="Your email"
                    autoComplete="off"
                    className="border-none shadow-none"
                  />
                </div>

                <Form.Button
                  isLoading={fetcher.state != "idle"}
                  value="subscribe"
                  variant="primary"
                  className="whitespace-nowrap"
                >
                  Join the community
                </Form.Button>
              </div>

              <Form.Turnstile
                meta={fields.token}
                sitekey="0x4AAAAAAAkcD2eq_IoLAt0e"
              />
            </Form>
          </div>
        </div>

        <div>
          <h2 className="text-2xl font-bold text-common-title">New Launches</h2>

          <div className="grid grid-cols-1 gap-8 mt-8">
            {data.products.today.map((product) => (
              <Item key={product.id} product={product} />
            ))}
          </div>
        </div>

        <div>
          <h2 className="text-2xl font-bold text-common-title">
            Launches From the Past Week
          </h2>

          <div className="grid grid-cols-1 gap-8 mt-8 md:grid-cols-2">
            {data.products.week.map((product) => (
              <Item key={product.id} product={product} />
            ))}
          </div>
        </div>

        {/*<div className="hidden col-span-5 pl-8 ml-8 space-y-8 border-l lg:col-span-4 md:block border-common-border">
            <div className="p-4 transition-all bg-white border shadow-sm border-common-border rounded-xl hover:shadow-soft">
              <h2 className="font-bold text-common-title">Ad Spot</h2>

              <p className="mt-1 text-sm text-common-subtitle">
                Your product can be advertise in this spot.
              </p>
            </div>

            {data.tags.map((tag) => (
              <Tag key={tag.id} tag={tag} />
            ))}
          </div>
        </div>*/}

        <Outlet />
      </Main>

      <Footer />
    </Layout>
  );
}
